import _env from "@/plugins/env";

export class LiveSessionUtility {
  static buildSkymeetUri(uri, autoSubmit) {
    return (
      (_env("BASE_URL") ?? "/") +
      "live-session/skymeet?meeting=" +
      encodeURI(uri) +
      "&autoSubmit=" +
      (autoSubmit ? 1 : 0)
    );
  }

  static buildZoomUri(uri, returnUrl, eventId) {
    return (
      (_env("BASE_URL") ?? "/") +
      "live-session/zoom?meeting=" +
      encodeURI(uri) +
      "&returnUrl=" +
      returnUrl +
      "&eventId=" +
      eventId
    );
  }
}
