<template>
  <svg data-v-51195181="" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="1 0 28 28">
    <g id="Icon-Set-Filled" transform="translate(14.943) rotate(45)">
      <path id="Sottrazione_1" data-name="Sottrazione 1" d="M10.566,21.133A10.567,10.567,0,0,1,3.095,3.094,10.567,10.567,0,0,1,18.038,18.039,10.5,10.5,0,0,1,10.566,21.133ZM9.237,14.673a.414.414,0,0,0-.293.707l1.463,1.463a.414.414,0,0,0,.585,0l1.463-1.463a.414.414,0,0,0-.293-.707.409.409,0,0,0-.292.121l-.757.756v-3.1a.414.414,0,1,0-.827,0v3.1l-.757-.756A.409.409,0,0,0,9.237,14.673Zm3.218-4.388a.414.414,0,1,0,0,.827h3.1l-.756.757a.414.414,0,0,0,0,.585.414.414,0,0,0,.585,0l1.462-1.463a.413.413,0,0,0,0-.585L15.38,8.944a.414.414,0,1,0-.585.585l.756.757ZM6.311,8.823a.413.413,0,0,0-.293.121L4.556,10.407a.413.413,0,0,0,0,.585l1.462,1.463a.414.414,0,1,0,.585-.585l-.756-.757h3.1a.414.414,0,1,0,0-.827h-3.1L6.6,9.529a.413.413,0,0,0-.292-.706Zm3.974-2.975v3.1a.414.414,0,0,0,.827,0v-3.1l.757.756a.413.413,0,0,0,.585-.584L10.992,4.556a.413.413,0,0,0-.585,0L8.944,6.019a.413.413,0,1,0,.585.584l.755-.754Z" transform="translate(0 0)" fill="#4C4C4C">
      </path>
    </g>
  </svg>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "SvgFullscreen"
})

export default class SvgFullscreen extends Vue {
}
</script>

