import { ApiHandler } from "@/lib/ApiHandler";

class LiveSessionService extends ApiHandler {
  constructor() {
    super("/api/liveSessions");
  }

  async list(id) {
    return await this.get(`byUser/${id}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(data) {
    return await this.get("saved", data)
      .then(res => res.json())
      .then(res => res.result);
  }

  async setAvailable(id) {
    return await this.patch(`${id}/available-in-vp`, {})
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async setNotAvailable(id) {
    return await this.patch(`${id}/not-available-in-vp`, {})
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async getParentsByEvent(idEvent) {
    return await this.get(`${idEvent}/availableParents`)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }
}

export const liveSessionService = new LiveSessionService();
