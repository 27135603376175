<template>
  <div>
    <div
      class="window"
      v-show="!isIcon"
      :class="[
        isFullScreen ? 'window__fullscreen' : null,
        type == 'skymeeting' ? 'window__skymeetin' : null,
        type == 'zoom' ? 'window__zoom' : null,
        type == 'presentation' ? 'window__presentation' : null,
      ]"
    >
      <div class="window__header">
        <p class="window__title">{{ title }}</p>
        <div class="window__container-icon">
          <div class="window__resize" @click="resize">
            <SvgFullscreen />
          </div>
          <div class="window__reduce" @click="reduce">-</div>
        </div>
      </div>
      <slot></slot>
    </div>
    <div v-if="isIcon" class="window__reduced" @click="reduce">
      {{ title }}
    </div>
  </div>
</template>

<script>
import Base from "@/mixins/base.js";
import Component from "vue-class-component";
import SvgFullscreen from "@/components/svgIcons/SvgFullscreen";

@Component({
  components: {
    SvgFullscreen,
  },
  props: {
    type: String,
    title: String,
  }
})
export default class Window extends Base {
  isFullScreen = false;
  isIcon = false;

  resize() {
    this.isFullScreen = !this.isFullScreen;
  }

  reduce() {
    this.isIcon = !this.isIcon;
    if (this.isIcon) {
      this.isFullScreen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.window {
  width: calc(50% - 45px);
  height: 80%;
  max-height: calc(100% - 150px);
  position: absolute;
  border-radius: 10px;
  top: 30px;
  left: 30px;

  &__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dbdbdb",GradientType=1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }

  &__title {
    text-transform: uppercase;
    color: #505050;
    font-weight: 900;
    font-size: 13px;
  }

  &__container-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50px;
  }

  &__presentation {
    top: 30px;
    left: calc(50% + 15px);
    width: calc(50% - 45px);
    height: 80%;
  }

  &__reduced {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 219, 219, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dbdbdb",GradientType=1);
    position: absolute;
    bottom: 10px;
    left: 25px;
    padding: 10px;
    text-transform: uppercase;
    color: #505050;
    font-weight: 900;
    font-size: 13px;
    border-radius: 10px;
    transition: 300ms;
    cursor: pointer;
    z-index: 1000;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__fullscreen {
    top: 30px;
    left: 30px;
    width: calc(100% - 60px);
    height: 80%;
    z-index: 1000;
  }

  &__reduce {
    color: #4c4c4c;
    font-size: 30px;
    cursor: pointer;
  }

  &__resize {
    cursor: pointer;
  }
}
</style>
