<template>
  <div class="live-session-detail-page">
    <Window v-if="platform == 'Skymeeting'" type="skyemeeting" title="Meeting">
      <iframe
        class="live-session-detail-page__iframe"
        seamless="seamless"
        :src="localized.speakerLinkUri"
        allowusermedia=""
        allow="microphone *; camera *; autoplay; display-capture *;"
      />
    </Window>
    <Window v-if="platform == 'Zoom'" type="zoom" title="Meeting">
      <iframe
        class="live-session-detail-page__iframe"
        seamless="seamless"
        :src="localized.speakerLinkUri"
        allowusermedia=""
        allow="microphone *; camera *; autoplay;"
      />
    </Window>
    <Window
      v-if="localized.speakerPresentationLink"
      type="presentation"
      title="Presentation"
    >
      <iframe
        class="live-session-detail-page__iframe"
        seamless="seamless"
        :src="localized.speakerPresentationLink"
        allowusermedia=""
        allow="microphone *; camera *; autoplay;"
      />
    </Window>
    <div class="live-session-detail-page__bottom">
      <p class="live-session-detail-page__info">{{ localized.title }}</p>
      <p class="live-session-detail-page__status">
        STATUS: {{ appointmentStatus }}
      </p>
      <router-link to="/live-sessions">
        <p class="live-session-detail-page__back">Back</p>
      </router-link>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Base from "@/mixins/base.js";
import Component, { mixins } from "vue-class-component";
import Loading from "@/components/loading";
import Window from "@/components/dom/window";
import { LiveSessionUtility } from "@/lib/LiveSessionUtility.js";
import { liveSessionService } from "@/services/liveSessionService.js";
import { localStorageService } from "@/services/localStorageService.js";


@Component({
  components: {
    Loading,
    Window,
  },
  watch: {},
})
export default class LiveSessionDetail extends mixins(Base) {
  isLoading = true;
  localized = {
    title: "",
    speakerLinkUri: null,
    speakerPresentationLink: null,
  };
  model = null;

  get platform() {
    if (!this.model) return null;

    return this.model.platform;
  }

  updateUris() {
    this.localized.speakerLinkUri =
      this.getLocalizedField(
        this.model.localizations,
        this.$route.params.lang,
        this.model.idEvent,
        "speakerLinkUri"
      ) ?? null;
    this.localized.speakerPresentationLink =
      this.getLocalizedField(
        this.model.localizations,
        this.$route.params.lang,
        this.model.idEvent,
        "speakerPresentationLinkUri"
      ) ?? null;

    if (this.model?.platform === "Zoom") {
      if (this.localized.speakerLinkUri) {
        this.localized.speakerLinkUri = LiveSessionUtility.buildZoomUri(
          this.localized.speakerLinkUri,
          this.$router.currentRoute.fullPath,
          this.model.idEvent
        );
      }
      if (this.localized.speakerPresentationLink) {
        this.localized.speakerPresentationLink +=
          "&eventId=" + this.model.idEvent;
      }
    }
  }

  updateData() {
    this.localized.title =
      this.getLocalizedField(
        this.model.localizations,
        this.$route.params.lang,
        this.model.idEvent,
        "title"
      ) ?? null;
    this.localized.description =
      this.getLocalizedField(
        this.model.localizations,
        this.$route.params.lang,
        this.model.idEvent,
        "description"
      ) ?? null;
  }

  async init() {
    let userId = this.$store.state.authStore.impersonatedUser ?? this.loggedUserId;
    try {
      this.model = await liveSessionService.read({
        idLiveSession : this.$route.params.id,
        idUser : userId
      });
      this.isLoading = false;
    } catch (error) {
      alert(error);
    }

    // uris get language from window location
    this.updateUris();
    // uris get language from store
    this.updateData();
  }

  get appointmentStatus() {
    return this.model?.status;
  }

  get loggedUserId() {
    return localStorageService.getLoggedUser()?.id;
  }

  afterBaseInit() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.live-session-detail-page {
  background-color: #a3cee8;
  width: 100%;
  height: 100%;

  &__iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &__bottom {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 900;
    background-color: #505050;
    color: #ebebeb;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__info {
    margin: 20px;
  }

  &__status {
    margin: 20px;
  }

  &__back {
    margin: 20px;
    text-decoration: none;
    color: #ebebeb;
    &:hover {
      color: #b9b9b9;
    }
  }
}
</style>
